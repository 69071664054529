import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Button from '../../components/Button/Button'
import LgbtDecorLine from '../../components/LgbtDecorLine/LgbtDecorLine'
import LgbtDecorLineSingle from '../../components/LgbtDecorLineSingle/LgbtDecorLineSingle'
import styles from './styles/Footer.module.scss'

import { useGetFooterQuery } from './api/footerApi'
import { LgbtToast } from '../../components/LgbtToast/LgbtToast'

function Footer() {

  const { data, error, isError, isLoading } = useGetFooterQuery()
  const dispatch = useDispatch();
  const siteConfig = useSelector(state => state.site)
  var decorLine = 'multiple';

  useEffect(() => {
    if (isError && error) {
      LgbtToast({ message: error.status, type: 'error' })
    }
  }, [dispatch, isError, error]);

  if (isLoading) {
    return <div>Loading...</div>
  }
  
  if (siteConfig.domain?.includes('bmejobs')) {
    decorLine = data?.footer_decor_line_color.bmejobs;
  }
  else if (siteConfig.domain?.includes('disabilityjob')) {
    decorLine = data?.footer_decor_line_color.disabilityjob;
  }
  else if (siteConfig.domain?.includes('neurodiversityjobs')) {
    decorLine = data?.footer_decor_line_color.neurodiversityjobs;
  }
  else {
    decorLine = data?.footer_decor_line_color.lgbtjobs;
  }

  return (
    <footer>
      <div className={styles.wrapper + ' container'}>
        <div className={styles.subscribeWrapper}>
          <h2>{data?.title}</h2>
          <p dangerouslySetInnerHTML={{ __html: data?.content }} />
          <Button className={styles.button} to={data?.Contact_button?.link}>{data?.Contact_button?.label}</Button>
        </div>
        <ul className={styles.menuLinks}>
          {data?.FooterMenu?.MenuLinks?.map((link, index) => (
            <li className={styles.menuLink} key={index}>
              <Link to={link?.link}>{link?.label}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.copyright + ' container'}>
        {
            <LgbtDecorLineSingle color='white' className={styles.decorderline} />
        }
        <p>{data?.copy_rights}</p>
      </div>
    </footer>
  )
}

export default Footer
