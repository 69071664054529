import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';

import { useGetSearchResultsQuery } from '../JobSearchBox/api/searchApiSlice'
import JobSearchBox from '../../features/JobSearchBox'
import JobsList from '../../components/JobsList/JobsList';
import bannerImage from '../../assets/images/seachbanner.png'
import NeurodiversityBannerImage from '../../assets/images/neurodiversitySearchBanner.png'
import BmeBannerImage from '../../assets/images/bmeBanner.webp'
import DisabilityBannerImage from '../../assets/images/disabilityBanner.webp'
import useSearchForm from '../JobSearchBox/hooks/useSearchForm';
import { useSelector } from 'react-redux';
import styles from './styles/searchPage.module.scss'
import JobSearchFilter from '../JobSearchBox/components/JobSearchFilter/JobSearchFilter';
import Pagination from '../../components/Pagination/Pagination';



function JobSearchPage() {

  const loc = useLocation();
  const queryParams = new URLSearchParams(loc.search);
  const keyword = queryParams.get('keyword');
  const location = queryParams.get('location');
  const minSalary = queryParams.get('salary-min');
  const maxSalary = queryParams.get('salary-max');
  const postedDate = queryParams.get('posted-date')
  const contractTypes = queryParams.get('contract-types');
  const location_radius = queryParams.get('location-radius');
  const sector = queryParams.get('sector');
  const scrollRef = useRef(null);
  const bannerRef = useRef(null);
  const [page, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const page_size = 15;

  const siteConfig = useSelector(state => state.site)
  let bannerImageSrc = bannerImage
  let title = <><span className={styles.title}><b>Thousands of live jobs with Inclusive employers</b></span></>

  if (siteConfig.domain?.includes('neurodiversityjobs')) {
    bannerImageSrc = NeurodiversityBannerImage
  }
  else if (siteConfig.domain?.includes('bmejobs')) {
    bannerImageSrc = BmeBannerImage
  }
  else if (siteConfig.domain?.includes('disabilityjob')) {
    bannerImageSrc = DisabilityBannerImage
  };
  const { searchForm } = useSearchForm();

  const { data, isLoading, isFetching } = useGetSearchResultsQuery({
    keyword: keyword || '',
    location: location || '',
    contractTypes: (contractTypes == null) ? [] : contractTypes.split(','),
    minSalary: minSalary || null,
    maxSalary: maxSalary || null,
    postedDate: postedDate || null,
    sector: (sector == null) ? [] : sector.split(','),
    location_radius: location_radius ?? 0,
    page_size: page_size,
    page: page,
  });

  useEffect(() => {
    if (data) {
      setTotalPages(Math.ceil(data.count / page_size));
    }
  }, [data])

  useEffect(() => {
    setPageNumber(1);
  }, [keyword, location, contractTypes, minSalary, maxSalary, postedDate, sector])

  const forwardScrollRef = (ref) => {
    scrollRef.current = ref;
  }

  useEffect(() => {
    if (keyword && !isFetching && !isLoading) {
      const y =
        scrollRef.current.getBoundingClientRect().top - bannerRef.current.getBoundingClientRect().top - 20;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [keyword, isFetching, isLoading]);

  useEffect(() => {
    const page = queryParams.get('page');
    setPageNumber(page ? parseInt(page, 10) : 1);
  }, [loc]);
  useEffect(() => {
    
  },[page])
  return (
    <div className={styles.searchPage}>
       <div className={styles.banner} ref={bannerRef}>
        <div className={`${styles.cntWrap} container`}>
          <div className={styles.cnt}>
            {title}
          </div>
        </div>
      </div>
      <div className="container">
        <JobSearchBox className={styles.searchBox} ref={forwardScrollRef} searchForm={searchForm} />
      </div>
      <div className={styles.gridOuter}>
        <div className="container">
          <div className={styles.searchComponentWrapper}>
            <JobSearchFilter form={searchForm} />
            <JobsList jobs={data} isLoading={isLoading} isFetching={isFetching} />
          </div>
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            pageSize={page_size}
            onPageChange={setPageNumber}
          />
        </div>
      </div>
    </div>
  )
}

export default JobSearchPage
