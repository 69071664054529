import React, { useState, useEffect } from 'react'
import styles from '../StaticBanner/StaticBanner.module.scss'

const StaticBanner = (props) => {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const banner = props.background
    return (
        <div className={`${styles.homeBanner} ${props.page === 'employer' ? styles.employerBanner : ''} ${props.page === 'privacyAndTerms' ? styles.privacyTerms : ''} ${props.page === 'jobAlert' ? styles.jobAlert : ''}`} style={props.gradient}>
            {
                !props.isLoading ?
                    <div className={styles.animWrapper}>
                        {
                            banner ?
                                <img src={banner} alt="" />
                                : ''
                        }
                    </div>
                    : ''
            }
            <div className={styles.bannerCntWrapper + ' container'}>
                <span className={styles.headline}>
                    {props.title}
                </span>
                <span className={styles.cnt}>{props.subtitle}</span>
                {props.children}
            </div>
        </div>
    )
}

export default StaticBanner