import React, { forwardRef } from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import { IoLocationOutline } from 'react-icons/io5';
import { IoClose } from "react-icons/io5";
import { useLocation } from 'react-router-dom';
import Select from 'react-select'
import { useState, useEffect } from 'react';

import SuggestionList from './components/SuggestionList/SuggestionList'
import { useGetLocationSearchResultsQuery, useGetKeywordSearchResultsQuery } from './api/searchApiSlice'

import styles from './styles/JobSearchBox.module.scss'

const JobSearchBox = forwardRef(({ className, searchForm }, ref) => {

    const [isDropdownVisible, setIsDropdownVisible] = useState(false)
    const { data: locSuggestion, isLoading: locLoad, isFetching: locFetch } = useGetLocationSearchResultsQuery({
        location: searchForm?.values?.location || '',
    });
    const { data: keywordSuggestion, isLoading: keywordLoad, isFetching: keywordFetch } = useGetKeywordSearchResultsQuery({
        keyword: searchForm?.values?.keyword || '',
    });
    const location = useLocation();
    const clearJobSearchBox = (field) => {
        searchForm.setFieldValue(field, '');
    }

    const locationOptions = [
        { value: 0, label: 'Distance' },
        { value: 5, label: '5 miles' },
        { value: 10, label: '10 miles' },
        { value: 20, label: '20 miles' },
        { value: 30, label: '30 miles' },
        { value: 50, label: '50 miles' },
    ]

    useEffect(() => {
        if (searchForm.values.location) {
            setIsDropdownVisible(true)
        } else {
            setIsDropdownVisible(false)
        }
    }, [searchForm.values.location])

    return (
        <>
            <div className={`${styles.searchWrapper} ${className}`} ref={ref}>
                <form action="" onSubmit={searchForm?.handleSubmit}>
                    <div className={styles.inputWarp}>
                        <IoSearchOutline />
                        <input
                            type="text"
                            name="keyword"
                            autoComplete="off"
                            value={searchForm?.values?.keyword}
                            onChange={searchForm?.handleChange}
                            id="keyword"
                            placeholder="Keyword/Job Title..." />
                        {searchForm?.values?.keyword ?
                            <button type="button" className={styles.clearBtn} onClick={() => { clearJobSearchBox('keyword') }}><IoClose /></button> : null}
                        <SuggestionList keyword={searchForm?.values?.keyword} data={keywordSuggestion} setValue={(value) => { searchForm.setFieldValue('keyword', value) }} >
                        </SuggestionList>
                    </div>
                    <div className={isDropdownVisible ? `${styles.inputOuter} ${styles.dropdownInputOuter}` : `${styles.inputOuter}`}>
                        <div className={styles.inputWarp}>
                            <IoLocationOutline />
                            <input
                                type="text"
                                name="location"
                                autoComplete="off"
                                value={searchForm?.values?.location}
                                onChange={searchForm?.handleChange}
                                id="location"
                                placeholder="Location..." />
                            {searchForm?.values?.location ?
                                <button type="button" className={styles.clearBtn} onClick={() => { clearJobSearchBox('location') }}><IoClose /></button> : null}
                        </div>
                        <SuggestionList keyword={searchForm?.values.location} data={locSuggestion} setValue={(value) => { searchForm.setFieldValue('location', value) }} >
                        </SuggestionList>
                    </div>
                    {
                        isDropdownVisible ?
                            <div className={`${styles.inputOuter} ${styles.selectWrapper}`}>
                                 <Select
                                    className={styles.radiusSelect}
                                    options={locationOptions}
                                    isClearable={false}
                                    isSearchable={false}
                                    value={locationOptions.find(option => option.value === searchForm.values.location_radius) || locationOptions.find(option => option.value === 0) }
                                    onChange={(selectedOption) =>
                                        searchForm.setFieldValue('location_radius', selectedOption.value)
                                    }
                                    placeholder={"Distance"}
                                />
                            </div>
                            : ''
                    }
                    <input type="submit" value="Search" className={styles.searchBtn} />
                </form>
            </div>
        </>
    )
})

export default React.memo(JobSearchBox)